import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useAnimation } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { PaymentOption } from '../../../../../api/apiTypes/shopApiTypes';
import DownArrowStrokeIconSvg from '../../../../../components/icons/DownArrowStrokeIconSvg';
import { FlexBox, MotionBox, Typography } from '../../../../../components/styleds';
import { VisaBrandIcon } from '../../../../Cart/components';
import { useGetUserMeetingBookings } from '../../../../Meeting/apiQueries/useMeetingBookings';
import OrderCard from '../components/OrderCard';
import OrderSectionItem from '../components/OrderSectionItem';
import { PaymentSectionProps } from './types';

const PaymentSection: FC<PaymentSectionProps> = ({ isLoadingOrder, order, canteenReferencesConfig }) => {
    const { t } = useTranslation('Food', { keyPrefix: 'foodForMeeting' });

    const { data: bookedMeetingList = [], isLoading: isBookedMeetingListLoading } = useGetUserMeetingBookings();

    const [isExtraContentOpened, setIsExtraContentOpened] = useState<boolean>(false);

    const cardAnimate = useAnimation();
    const dividerAnimate = useAnimation();
    const arrowAnimate = useAnimation();

    const extraContentHandler = useCallback(
        (val: boolean) => () => {
            setIsExtraContentOpened(!val);
        },
        [],
    );

    useEffect(() => {
        dividerAnimate?.start({
            opacity: isExtraContentOpened ? 1 : 0,
            transition: {
                duration: 0.5,
            },
        });
        cardAnimate?.start({
            height: isExtraContentOpened ? 'auto' : '0px',
            opacity: isExtraContentOpened ? 1 : 0,
            transition: {
                duration: 0.5,
                ease: [0.4, 0, 0.2, 1],
            },
        });
        arrowAnimate?.start({
            rotate: isExtraContentOpened ? 180 : 0,
            transition: {
                duration: 0.5,
                ease: [0.4, 0, 0.2, 1],
            },
        });
    }, [isExtraContentOpened, cardAnimate, arrowAnimate, dividerAnimate]);

    const currentMeeting = useMemo(
        () => bookedMeetingList.find((meeting) => meeting.id === order?.attachedMeetingId),
        [bookedMeetingList, order?.attachedMeetingId],
    );

    const isInvoice = useMemo(() => order?.paymentType === PaymentOption.CustomerInvoice, [order]);

    const customReferencesRender = useMemo(() => {
        return order?.customReferences.map((reference) => {
            const predefinedValuesListValue = canteenReferencesConfig.find(
                (el) => el.name === reference.name,
            )?.predefinedValuesList;
            if (predefinedValuesListValue) {
                const value =
                    predefinedValuesListValue.find((el) => el.id === reference.value)?.name || reference.value;
                return <OrderSectionItem key={reference.name} label={reference.name} value={value} />;
            } else return null;
        });
    }, [order?.customReferences, canteenReferencesConfig]);

    const cardDetailsValue = useMemo(() => {
        if (isInvoice) return '';
        else {
            return (
                <FlexBox gap={'0.286rem'} align={'center'}>
                    {order?.cardBrand === 'visa' && <VisaBrandIcon />}
                    <Typography fontWeight={700} color={'#000'} fontSize={'1.143rem'} lineHeight={'1.714rem'}>
                        {order?.cardNumber}
                    </Typography>
                </FlexBox>
            );
        }
    }, [isInvoice, order]);

    const paymentMethodText = useMemo(
        () =>
            isInvoice
                ? 'foodOrderPaymentSectionPaymentInvoiceMethodValue'
                : order?.paymentType === PaymentOption.Vipps
                ? 'foodOrderPaymentSectionPaymentVippsMethodValue'
                : 'foodOrderPaymentSectionPaymentCardMethodValue',
        [order, isInvoice],
    );

    return (
        <OrderCard
            title={t('foodOrderPaymentSectionTitle')}
            isSkeleton={isLoadingOrder || isBookedMeetingListLoading}
            pd={'0rem'}
            titlePd={'1.714rem 1.714rem 0rem'}
        >
            <FlexBox dir={'column'} gap={'0.571rem'} pd={'0rem 1.714rem'}>
                {order && (
                    <FlexBox pdB={order.paymentType === PaymentOption.Vipps ? '1.714rem' : '0rem'}>
                        <OrderSectionItem
                            label={t('foodOrderPaymentSectionPaymentMethod')}
                            value={t(paymentMethodText)}
                        />
                    </FlexBox>
                )}
                {isInvoice && (
                    <MotionBox
                        animate={dividerAnimate}
                        initial={{ opacity: 0 }}
                        height={'1px'}
                        background={'#D4D6D7'}
                        width={'100%'}
                    />
                )}
            </FlexBox>
            {isInvoice && (
                <MotionBox
                    animate={cardAnimate}
                    initial={{ height: '0px', opacity: 0, overflow: 'hidden' }}
                    dir={'column'}
                    gap={'0.857rem'}
                    pd={'0rem 1.714rem'}
                    pdT={'0rem'}
                >
                    {currentMeeting && (
                        <FlexBox dir={'column'} gap={'0.143rem'}>
                            <Typography
                                color={'#475156'}
                                fontSize={'1rem'}
                                fontWeight={700}
                                lineHeight={'1.714rem'}
                                letterSpacing={'0.143rem'}
                            >
                                {t('foodOrderPaymentSectionTitleOfMeeting')}
                            </Typography>
                            <Typography color={'#475156'} fontSize={'1rem'} fontWeight={400} lineHeight={'1.643rem'}>
                                {currentMeeting.title}
                            </Typography>
                        </FlexBox>
                    )}
                    {customReferencesRender}
                </MotionBox>
            )}
            {isInvoice && (
                <FlexBox
                    align={'center'}
                    justify={'center'}
                    cursor={'pointer'}
                    background={'#F1F5F9'}
                    onClick={extraContentHandler(isExtraContentOpened)}
                    pd={'0.571rem 0rem'}
                >
                    <MotionBox animate={arrowAnimate} initial={{ rotate: 0 }} width={'auto'}>
                        <DownArrowStrokeIconSvg />
                    </MotionBox>
                </FlexBox>
            )}

            {order?.paymentType === PaymentOption.Stripe && (
                <FlexBox dir={'column'} gap={'0.857rem'} pd={'1.714rem'} pdT={'0rem'}>
                    <OrderSectionItem label={t('foodOrderPaymentSectionCardDetails')} value={cardDetailsValue} />
                </FlexBox>
            )}
        </OrderCard>
    );
};

export default PaymentSection;
