import { PropsWithChildren, ReactElement } from 'react';
import styled, { css } from 'styled-components';

type ErrorMessageProps = { absolutePosition: boolean; fontSize?: string };
const ErrorMessage = styled.p`
    color: var(--error-color);
    font-size: ${({ fontSize }) => fontSize || '0.857rem'};

    margin-right: 0.5em;

    ${({ absolutePosition }: ErrorMessageProps) =>
        absolutePosition &&
        css`
            position: absolute;
            bottom: -1.3rem;
        `}
`;

interface FieldErrorMessageProps {
    absolutePosition?: boolean;
    id?: string;
    fontSize?: string;
}

const FieldErrorMessage = ({
    children,
    absolutePosition = false,
    id,
    fontSize,
}: PropsWithChildren<FieldErrorMessageProps>): ReactElement => {
    return (
        <ErrorMessage role="alert" absolutePosition={absolutePosition} id={id} fontSize={fontSize}>
            {children}
        </ErrorMessage>
    );
};

export default FieldErrorMessage;
