import { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import Toggle, { type ToggleProps } from './Toggle';

type ContainerProps = {
    checked: boolean;
    disabled: boolean;
};
const Container = styled.div`
    border: ${({ checked, disabled }: ContainerProps) =>
        checked && !disabled ? '1px solid var(--success-background-color)' : 'none'};
    padding: 1.714rem;
    ${({ checked }: ContainerProps) => checked && 'box-shadow: 0 0.285rem  0.714rem 0 #00000026'};
    border-radius: 0.428rem;
    background-color: #fff;
    width: 100%;
    height: 100%;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
`;
const TopContentWrapper = styled.div`
    padding-bottom: 1.142rem;
`;
const BottomContentWrapper = styled.div`
    padding-top: 1.142rem;
`;

interface ToggleCardProps {
    topContent?: ReactNode;
    bottomContent?: ReactNode;
    toggleConfig: ToggleProps;
}
const ToggleCard = ({ topContent, bottomContent, toggleConfig }: ToggleCardProps): ReactElement => {
    return (
        <Container checked={!!toggleConfig.checked} disabled={!!toggleConfig.disable}>
            {topContent && <TopContentWrapper>{topContent}</TopContentWrapper>}
            <Toggle {...toggleConfig} />
            {bottomContent && <BottomContentWrapper>{bottomContent}</BottomContentWrapper>}
        </Container>
    );
};
export default ToggleCard;
