import { AxiosError, AxiosResponse } from 'axios';
import type { InfiniteQueryResult, MutationResultPair, QueryResult } from 'react-query';
import { useInfiniteQuery, useMutation, useQuery, useQueryCache } from 'react-query';
import { Product, AllergenType, LunchProduct, GetInfiniteProductsParams } from '../../../api/apiTypes/shopApiTypes';
import {
    getProductById,
    getProductLunch,
    getProducts,
    getAllergens,
    removeProductById,
    getProductsByCanteen,
} from '../../../api/shopApi';

const AllergensCacheKey = 'AllergensCacheKey';
const ProductsCacheKey = 'ProductsCacheKey';
const ProductCacheKey = 'ProductCacheKey';
const ProductLunchCacheKey = 'ProductLunchCacheKey';
const ProductByCanteenCacheKey = 'ProductByCanteenCacheKey';

export const useGetInfiniteCompanyProducts = (
    data: GetInfiniteProductsParams,
    enabled: boolean,
): InfiniteQueryResult<Product[], Error | AxiosError<string>> => {
    const numberOfProducts = 20;

    return useInfiniteQuery<Product[], Error | AxiosError<string>>(
        [ProductsCacheKey, data],
        async (
            _,
            { sortingType, sortDirection, searchTerm, filters, orderDateTime, isDelivery },
            page,
        ): Promise<Product[]> => {
            const response = await getProducts({
                page: page ?? 0,
                pageSize: numberOfProducts,
                sortingType,
                sortDirection,
                searchTerm,
                filters,
                orderDateTime,
                isDelivery,
            });
            return response.data;
        },
        {
            getFetchMore: (lastPage, allPages) => {
                if (lastPage.length !== numberOfProducts) {
                    return false;
                }
                return allPages.length;
            },
            refetchInterval: 1000 * 60,
            keepPreviousData: false,
            enabled,
        },
    );
};

export const useGetProductsAllergens = (): QueryResult<AllergenType[], string | AxiosError<string> | Error> => {
    return useQuery([AllergensCacheKey], async () => {
        const result = await getAllergens();
        return result.data ?? [];
    });
};

export const useGetProductById = (id: string): QueryResult<Product, AxiosError<string> | Error> => {
    return useQuery(
        [ProductCacheKey, id],
        async () => {
            const result = await getProductById(id);
            return result.data;
        },
        {
            enabled: !!id,
            retry: 1,
        },
    );
};

export const useInvalidateProduct = (): ((id: string) => void) => {
    const queryCache = useQueryCache();
    return (id: string) => {
        queryCache.invalidateQueries([ProductCacheKey, id]);
    };
};

export const useDeleteProductById = (): MutationResultPair<
    AxiosResponse<void>,
    string | AxiosError<string> | Error,
    string,
    never
> => {
    return useMutation((id: string) => {
        return removeProductById(id);
    });
};

export const useGetProductLunch = (): QueryResult<LunchProduct[], AxiosError<string> | Error> => {
    return useQuery([ProductLunchCacheKey], async () => {
        const result = await getProductLunch();
        return result.data;
    });
};

export const useGetProductsByCanteen = (
    canteenId: string,
    productFetchIsEnabled: boolean,
): QueryResult<Product[], AxiosError<string> | Error> => {
    return useQuery(
        [ProductByCanteenCacheKey],
        async () => {
            const result = await getProductsByCanteen(canteenId);
            return result.data;
        },
        {
            enabled: productFetchIsEnabled,
        },
    );
};
