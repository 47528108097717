import { ReactElement, SyntheticEvent } from 'react';
import styled from 'styled-components';
import SvgIcon from '../icons/SvgIcon';

const disabledColor = '#677074';
const primaryColor = 'var(--primary-color)';

type WrapperProps = { disable: boolean };
const Wrapper = styled.div`
    opacity: ${({ disable }: WrapperProps) => (disable ? '0.5' : '1')};
`;

const StyledInput = styled.input`
    height: 0;
    width: 0;

    :checked {
        left: calc(100% - 2px);
        transform: translateX(-100%);
    }
`;

type StyledLabelProps = { isChecked: boolean; disable: boolean };
const StyledLabel = styled.label`
    background: ${({ isChecked, disable }: StyledLabelProps) =>
        isChecked && !disable ? 'var(--success-background-color)' : disabledColor};
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 2.857rem;
    min-width: 2.857rem;
    height: 1.429rem;
    border-radius: 1.143rem;
    position: relative;
    transition: background-color 0.2s;
    outline: none;
    :focus-visible {
        box-shadow: 0 0 0 1px var(--background-color),
            0 0 0 2px ${({ disable }: StyledLabelProps) => (disable ? disabledColor : primaryColor)};
    }
    svg {
        opacity: ${({ isChecked }: StyledLabelProps) => (isChecked ? 1 : 0)};
        transition: opacity 0.2s;
        padding-left: 0.214rem;
        width: 1.429rem;
        path {
            fill: white;
        }
    }
`;

type StyledSpanProps = { isChecked: boolean };
const StyledSpan = styled.span`
    content: '';
    position: absolute;
    top: 2px;
    width: 1.143rem;
    height: 1.143rem;
    border-radius: 1.429rem;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);

    left: ${({ isChecked }: StyledSpanProps) => (isChecked ? 'calc(100% - 2px);' : '2px;')};
    transform: ${({ isChecked }: StyledSpanProps) => (isChecked ? 'translateX(-100%);' : '')};
`;

const Description = styled.p`
    padding: 0.5rem 0 0 3.643rem;
    font-size: 0.857rem;
    color: var(--text-high-emphasis-color);
`;

type ToggleTextProps = { isChecked: boolean };
const ToggleText = styled.span`
    font-size: 1.143rem;
    padding-left: 0.8rem;
    color: var(--text-high-emphasis-color);
    font-weight: ${({ isChecked }: ToggleTextProps) => (isChecked ? 'bold' : 'normal')};
`;

export interface ToggleProps {
    name: string;
    toggleText?: string;
    description?: string;
    onChange(event: SyntheticEvent, isChecked: boolean): void;
    disable?: boolean;
    checked?: boolean;
    tabIndex?: number;
}

const Toggle = ({
    name,
    toggleText,
    description,
    onChange,
    disable = false,
    checked = false,
    tabIndex = 0,
}: ToggleProps): ReactElement => {
    const onChangeHandler = (e: SyntheticEvent): void => {
        if (!disable) {
            onChange(e, !checked);
        }
    };

    const keyDownHandler = (e: React.KeyboardEvent<HTMLElement>): void => {
        if (e.key === 'Enter') {
            onChangeHandler(e);
        }
    };

    return (
        <Wrapper disable={disable}>
            <StyledInput name={name} type="checkbox" hidden={true} checked={checked} onChange={onChangeHandler} />
            <div style={{ display: 'flex' }}>
                <StyledLabel
                    aria-checked={checked}
                    htmlFor={name}
                    isChecked={checked}
                    disable={disable}
                    tabIndex={tabIndex}
                    onKeyDown={keyDownHandler}
                    onClick={onChangeHandler}
                    role="checkbox"
                >
                    <SvgIcon name="CheckIcon" />
                    <StyledSpan isChecked={checked} />
                </StyledLabel>
                <ToggleText isChecked={checked}>{toggleText}</ToggleText>
            </div>
            {description && <Description>{description}</Description>}
        </Wrapper>
    );
};

export default Toggle;
