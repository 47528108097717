import { useQuery } from 'react-query';
import type { QueryResult } from 'react-query';
import { SimpleCanteen, Vendor } from '../../../api/apiTypes/foodForMeetingsApiTypes';
import { CanteenWithAvailabilityParams } from '../../../api/apiTypes/shopApiTypes';
import {
    getAllAvailableCanteens,
    getCanteensByVendorId,
    getDailyMenuCanteens,
    getSimpleCanteensByDefault,
} from '../../../api/foodForMeetingsApi';

export const CanteensCacheKey = 'CanteensCacheKey';
const CanteenByVendorId = 'CanteenByVendorId';
export const SimpleCanteensCacheKey = 'SimpleCanteensCacheKey';
export const SimpleCanteensWithDefaultCanteenCacheKey = 'SimpleCanteensWithDefaultCanteenCacheKey';

export const useGetAllAvailableCanteens = (
    { suggestedCanteenIds, isDelivery, orderDateTime, menuType }: CanteenWithAvailabilityParams,
    enabled?: boolean,
): QueryResult<Vendor[]> => {
    return useQuery(
        [CanteensCacheKey, suggestedCanteenIds, isDelivery, orderDateTime, menuType],
        async () => (await getAllAvailableCanteens({ suggestedCanteenIds, isDelivery, orderDateTime, menuType }))?.data,
        {
            enabled,
        },
    );
};
export const useGetCanteensByVendorId = (vendorId: string): QueryResult<Vendor[]> => {
    return useQuery([CanteenByVendorId, vendorId], async () => (await getCanteensByVendorId(vendorId))?.data, {
        enabled: !!vendorId,
    });
};

export const useGetSimpleCanteens = (): QueryResult<SimpleCanteen[]> =>
    useQuery([SimpleCanteensCacheKey], async () => (await getDailyMenuCanteens())?.data);

export const useGetSimpleCanteensByDefault = (): QueryResult<SimpleCanteen[]> =>
    useQuery([SimpleCanteensWithDefaultCanteenCacheKey], async () => (await getSimpleCanteensByDefault())?.data);
